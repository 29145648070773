import React from 'react';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import CardImageTitleDescription from '../../../components/card-image-title-description/card-image-title-description';
import { Grid } from '@material-ui/core';
import SectionContainerLayout from '../../../components/section-container-layout/section-container-layout';
export default function PlanYouTrip() {
  return (
    <Layout>
      <SEO lang='en' title='Plan your trip | Global Manufacturing & Industrialisation Summit' />
      <SectionContainerLayout title='PLAN YOUR TRIP' baseLink='/press' isDivider>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <CardImageTitleDescription
              image='travel-information'
              title='Travel Information'
              readMore='/press/plan-your-trip/travel-information'
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <CardImageTitleDescription
              image='covid-19-protocol'
              title='COVID-19 Protocols and Vaccine Status Information'
              readMore='/press/plan-your-trip/covid-19-and-vaccine'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CardImageTitleDescription
              image='press-and-media-information'
              title='Press and Media Information'
              readMore='/press/plan-your-trip/media-information'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CardImageTitleDescription
              image='booking'
              title='Hotel booking'
              readMore='/press/plan-your-trip/for-hotel-direct-booking'
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CardImageTitleDescription
              image='visa'
              title='Visa Requirements'
              readMore='/press/plan-your-trip/to-check-for-visa-requirements'
            />
          </Grid>
        </Grid>
      </SectionContainerLayout>
    </Layout>
  );
}
